<template>
  <Content title="中国领先的政采供应商">
    <div class="leading-supplier d-flex justify-content-between text-center th-pt-48">
      <div
        v-for="(advantage, i) in advantages"
        :key="i"
        class="advantage-item bg-white th-px-40 th-pt-50 d-flex flex-column align-items-center"
      >
        <img class="advantage-icon d-block" :src="advantage.image" />
        <div class="th-fs-32 lh-1 fw-bold th-mt-31">{{ advantage.title }}</div>
        <div class="th-fs-24 th-mt-58 advantage-desc">{{ advantage.desc }}</div>
      </div>
    </div>
    <div class="text-center th-mt-74">
      <button type="button" class="btn btn-primary p-0">
        <a href="./gp.html" class="d-flex justify-content-center align-items-center">
          <div class="text-white me-3">点击了解更多</div>
          <img src="/images/pages/index/btn-more.png" style="width: 18px" />
        </a>
      </button>
    </div>
  </Content>
</template>
<script>
import Content from '@/shared/components/content.vue'
import { getYearsFromJanuaryFirst } from '@/shared/utils/date.utils.js'
import { advantages } from './data'
export default {
  name: 'DesktopLeadingSupplier',
  components: { Content },
  setup() {
    const company_age = getYearsFromJanuaryFirst(new Date('1999-01-01'))
    const target = advantages.find(item => item.title.includes('company_age'))
    
    target.title = target.title.replace('company_age', company_age)
    target.desc =  target.desc.replace('company_age', company_age)
    
    return { advantages }
  },
}
</script>

<style lang="scss" scoped>
.content {
  background: url('/images/pages/index/leading-supplier-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.advantage-item {
  width: 360px;
  height: 420px;
  box-shadow: 0px 4px 20px 0px #f0f2f4;
  border-radius: 8px;
}

.advantage-icon {
  height: 76px;
}

.advantage-desc {
  color: #333333;
}
</style>
